.root {
  position: fixed;
  padding: 0 !important;
  top: 0px;
  left: 0px;
  width: calc(100% - 48px);
  height: var(--nav-bar-height) !important;
  margin: 0 24px;
  z-index: 1;
}

.content {
  padding: 0px 16;
  line-height: normal;
  display: flex;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(15px);
}

.header {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px;
}

.backTo {
  color: #b3afaf;
  display: inline-block;
  margin-left: 10px;
  padding-top: 4px;
}

.logo {
  margin-right: 38px;
}

.menu {
  border-bottom: none !important;
  background: none !important;
  flex: 1 1 0;
}

.menu li {
  padding: 8px 16px !important;
}

.menu li span {
  color: #807d7d !important;
}

.menu li::after {
  border: none !important;
}

.rightMenu {
  height: 100%;
}

.rightMenu :global .ant-space,
.rightMenu :global .ant-space-item,
.rightMenu :global .ant-dropdown-trigger {
  height: 100%;
  display: flex;
  align-items: center;
}

.btnLoginWrapper {
  display: inline-flex;
  margin-top: 21px;
}

.btnLoginContent {
  padding: 8px 24px 5px;
  background-color: #fff;
  color: var(--font-blue);
  font-weight: 700;
  border: 1px solid var(--font-blue);
  border-radius: 5px;
  font-family: "Azo Sans";
}

.triangle {
  width: 12px;
  height: 22px;
  margin-top: 6px;
  margin-left: 5px;
}

.emailUser {
  font-size: 16px;
  color: #353434;
  display: inline-block;
  margin-right: 8px;
}
