@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.dark.less";
@import "./overrides.less";
@import "./fonts/AzoSans/styles.css";
@import "./fonts/Avenir/styles.css";

:root {
  /* Colors: */
  --pink-indicator: #d40159;
  --green-indicator: #00fe9a;
  --orange-dark: #8b4500;
  --orange-main: #ffba00;
  --turqoise-dark: #09625d;
  --accent-turqoise: #0d9d95;
  --accent-blue: #4192ff;
  --blue-contrast: #001d57;
  --black-main: #19222b;
  --dark-background: #09080d;
  --dark-background-light: #40454f;
  --koyu-mor: #0a1752;
  --gri-lacviert: #14224b;
  --koyu-lacivert: #656d81;
  --lacivert: #001a63;
  --neon-mavi: #00f5ff;
  --tint-3: #bfc1c3;
  /* Sizes: */
  --nav-bar-height: 75px;
  --surface-padding: 23px 24px;
  --font-blue: #419ad7;
  --text-color: #b3afaf;
}

/*add font Ethnocentric*/
@font-face {
  font-family: "Ethnocentric Rg";
  font-style: normal;
  font-weight: 400;
  src: local("Ethnocentric Rg"),
    url("https://fonts.cdnfonts.com/s/6220/ethnocentric rg.woff") format("woff");
}

body {
  background-color: #fafafa;
  overflow-y: auto !important;
  width: 100% !important;
  color: #fff;
}

/* Override antd */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.Ethnocentric-font {
  font-family: "Ethnocentric Rg", sans-serif;
}

.ant-form-item {
  margin-bottom: 19px;
}
//hide form message error
.ant-form-item-explain {
  display: none;
}

input {
  font-family: Avenir;
}

/*Reusable css*/
.container {
  max-width: 1366px;
  margin: 0 auto;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.primary {
  color: var(--accent-blue) !important;
}

.secondary {
  color: #bfc1c3 !important;
}

.success {
  color: var(--green-indicator) !important;
}

.error {
  color: var(--pink-indicator) !important;
}

.disabled {
  color: #788686 !important;
}

.default {
  color: #ffffff !important;
}

.underline {
  text-decoration: underline;
}

.avenir {
  font-family: Avenir;
}

.cursor {
  cursor: pointer;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.f-column {
  flex-direction: column;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.f-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.f-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.f-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.f-align-end {
  align-items: flex-end;
}

.divider-x {
  width: 100%;
  height: 1px;
  background-color: #ffffff80;
}

.divider-y {
  width: 1px;
  height: 40px;
  background-color: #ffffff80;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff8989;
  font-size: 12px;
  font-style: italic;
}

.ant-form-item-has-error
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-form-item-has-error
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-form-item-has-error .ant-input:not(.ant-input-disabled),
.ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover {
  border-color: #ff8989;
}

/* Spacing */

.mb-0 {
  margin-bottom: 0px;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.-mr-15 {
  margin-right: -15px;
}

.mb-4 {
  margin-bottom: 4px;
}

/* Font global */
.bolder {
  font-weight: 900 !important;
}

.bold {
  font-weight: 700 !important;
}

.medium {
  font-weight: 500 !important;
}
.small {
  font-weight: 300 !important;
}

.light {
  font-weight: 200 !important;
}

/* Text size */
.text-45 {
  font-size: 45px !important;
  color: #ffffff;
}

.text-30 {
  font-size: 30px !important;
  color: #ffffff;
}

.text-28 {
  font-size: 28px !important;
  color: #ffffff;
}

.text-26 {
  font-size: 26px !important;
  color: #ffffff;
}

.text-24 {
  font-size: 24px !important;
  color: #ffffff;
}

.text-22 {
  font-size: 22px !important;
  color: #ffffff;
}

.text-20 {
  font-size: 20px !important;
  color: #ffffff;
}

.text-18 {
  font-size: 18px !important;
  color: #ffffff;
}

.text-16 {
  font-size: 16px !important;
  color: #ffffff;
}

.text-14 {
  font-size: 14px !important;
  color: #ffffff;
}

.text-12 {
  font-size: 12px !important;
  color: #ffffff;
}

/* Custom tooltip antd */
.ant-tooltip-inner {
  color: #ffba00;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 12px;
}

.ant-tooltip-arrow-content {
  width: 20px;
  height: 12px;
}

/* Custom image antd */
.ant-image-mask-info {
  font-size: 14px;
}

.weight-900 {
  font-weight: 900;
}

.ant-spin-dot-item {
  background-color: var(--font-blue) !important;
}

.ant-modal-content {
  background: #fff !important;
}

.ant-modal-confirm-title,
.ant-modal-confirm-content {
  color: #419ad7 !important;
  font-size: 16px;
  font-weight: bold;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #807d7d !important;
}

.cancelTextBtn {
  color: var(--font-blue);
}
