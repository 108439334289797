.root {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 48px 24px 12px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.footerContainer a {
  color: #807d7d !important;
  margin-left: 2px;
}

.footerSecurity {
  width: 100%;
  padding-left: 24px;
  color: #419ad7 !important;
  width: 100%;
  background-color: transparent;
  font-size: 12px;
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.introText1 {
  font-size: 18px;
  color: #353434;
  font-weight: 600;
  margin: 0;
}

.introText2 {
  color: #419ad7;
  font-size: 16px;
  margin: 0;
}

.introText3 {
  color: #807d7d;
  font-size: 14px;
  margin: 0;
}

.footerRightContainer {
  height: 150px;
  justify-content: space-between;
}

@media screen and (max-width: 568px) {
  .footerContainer a {
    font-size: 10px;
  }
}

@media screen and (max-width: 576px) {
  .footerContainer {
    width: 100%;
    margin-right: 12px;
  }

  .footerSecurity {
    padding-left: 20px;
  }

  .introText1 {
    font-size: 13px;
  }

  .introText2 {
    font-size: 10px;
  }

  .introText3 {
    font-size: 10px;
  }
}

@media screen and (max-width: 414px) {
  .parentRoot {
    display: block;
    width: 100%;
  }
  .root {
    padding: 12px 24px 0;
  }

  .footerRightContainer {
    display: none !important;
  }
}
